<template>
  <div class="hamburger-menu-panel">
    <div class="hamburger-menu">
      <a href="/">Home</a>
      <a @click="hideOnClick" href="#about">About</a>
      <a @click="hideOnClick" href="#partners">Partners</a>
      <a @click="hideOnClick" href="#team">Team</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "hamMenu",
  methods: {
    hideOnClick() {
      this.$store.commit("setHideMobileMenu")

    }
  },
  computed: {
    mobileMenuState() {
      return this.$store.state.showMobileMenu
    }
  }
}
</script>
