<template>
  <div id="app">
    <indexMain></indexMain>
  </div>
</template>

<script>
import indexMain from './components/indexMain.vue'

export default {
  name: 'app',
  components: {
    indexMain
  },
}
</script>