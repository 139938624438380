<template>
  <div>
    <div data-aos="fade-in" class="header">
      <a href="#" name="top"></a>
      <div class="sm-container">
        <div class="header-wrapper">
          <div class="header-logo">
            <img v-show="header_logo" src="../assets/img/headerlogo.png" alt="logo" />
          </div>
          <div class="header-menu">
            <a href="/">Home</a>
            <a href="#about">About</a>
            <a href="#partners">Partners</a>
            <a href="#team">Team</a>
          </div>
          <div class="nav-icon" @click="toggleMobileMenu">
            <div :class="{ div1_active: mobileMenuState }" class="div1"></div>
            <div :class="{ div2_active: mobileMenuState }" class="div2"></div>
            <div :class="{ div3_active: mobileMenuState }" class="div3"></div>
          </div>
          <hamMenu data-aos="fade-left" v-if="mobileMenuState"></hamMenu>
        </div>
      </div>
      <div class="container">
        <div class="header-heading">
          <h1>We have what it takes</h1>
          <p
            class="header-heading-textbox"
          >We develop and invest in open source financial solutions for the future.</p>
          <p>Our approach:</p>
          <p>Prepare.Accelerate.Launch.</p>
        </div>
      </div>
    </div>

    <div data-aos="fade-in" class="about">
      <a href="#" name="about"></a>
      <div class="container">
        <div class="about-wrapper">
          <div class="about-block">
            <h2>About</h2>
            <p>
              OpenGate Technology is an independent fintech firm based in Estonia led by experienced banking
              and
              technology professionals in the region. The firm provides businesses, financial institutions and
              investors with independent fintech advice, project and execution management on several areas in
              scope of
              open banking, fintech software solutions, transactions infrastructure, regulation and corporate
              structuring.
            </p>
          </div>
          <div class="about-logo">
            <img src="../assets/img/logo.png" alt />
          </div>
        </div>
      </div>
    </div>
    <div data-aos="fade-in" class="partners">
      <a href="#" name="partners"></a>
      <div class="container">
        <img class="blackdots" src="../assets/img/dots_black.svg" alt="dots" />
        <div class="partners-wrapper">
          <h2>Partners</h2>
          <div class="partners-content">
            <div class="company-ellips">
              <div class="company-ellips-content">
                <div class="company-ellips-logo">
                  <img src="../assets/img/canopus.png" alt="canopus" />
                </div>
                <div class="company-ellips-text">
                  <p>
                    Canopus Innovative Technologies LLC is a software development company founded in
                    1992.
                  </p>
                </div>
              </div>
            </div>
            <div class="company-ellips">
              <div class="company-ellips-content">
                <div class="company-ellips-logo">
                  <img src="../assets/img/advapay.png" alt="advapay" />
                </div>
                <div class="company-ellips-text">
                  <p>
                    IT &amp; business consulting company that provides turnkey fintech solutions for
                    regulated
                    payment
                    service providers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div data-aos="fade-in" class="team">
      <a href="#" name="team"></a>
      <div class="sm-container">
        <div class="team-wrapper">
          <h2>Team</h2>
          <div class="team-content">
            <div class="gustav" @click="showModal">
              <img
                @click="lauri_modal_shown = false;gustav_modal_shown = true"
                class="team-image"
                src="../assets/img/gustav.png"
                alt="gustav"
              />
            </div>
            <div @click="showModal">
              <img
                @click="lauri_modal_shown = true;gustav_modal_shown = false"
                class="team-image"
                src="../assets/img/lauri.png"
                alt="lauri"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div data-aos="fade-in" class="meet">
      <img class="whitedots" src="../assets/img/dotswhite.svg" alt="dots" />
      <div class="sm-container">
        <h2>Meet Us</h2>
        <div class="meet-wrapper">
          <div class="meet-info-1">
            <div class="meet-heading-mobile">
              <h4>Previous</h4>
            </div>
            <a href="https://www.payexpo.com/" target="_blank" rel="noopener noreferrer">
              <img src="../assets/img/payexpo.png" alt="payexpo" />
            </a>
            <a href="https://www.money2020.com/" target="_blank" rel="noopener noreferrer">
              <img src="../assets/img/money2020.png" alt="money2020" />
            </a>
            <a
              href="https://www.konverentsid.ee/finreg2019/form/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img class="finreg" src="../assets/img/finreg.png" alt="finreg" />
            </a>
          </div>

          <div class="rectangle"></div>
          <div class="meet-info-2">
            <div class="meet-heading-mobile">
              <h4>Upcoming</h4>
            </div>
            <a href="https://www.fintechinn.lt/" target="_blank" rel="noopener noreferrer">
              <img src="../assets/img/fintechinn.png" alt="fintechinn" />
            </a>
            <a href="https://www.fintechconnect.com/" target="_blank" rel="noopener noreferrer">
              <img src="../assets/img/fintechconnect.png" alt="fintechconnect" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div data-aos="fade-in" class="footer">
      <div class="sm-container">
        <div class="footer-wrapper">
          <div class="footer-column-1">
            <img src="../assets/img/footerlogo.png" alt="logo" />
            <p>&copy; 2021. All rights reserved</p>
          </div>
          <div class="footer-column-2">
            <p>Narva mnt 7B</p>
            <p>10117 Tallinn, Estonia</p>
            <a href="mailto:info@opengate.dev">
              <p>info@opengate.dev</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <modalWindow data-aos="fade-down" v-if="modalState">
      <div v-if="!gustav_modal_shown" slot="modal-body-gustav"></div>
      <div v-if="!lauri_modal_shown" slot="modal-body-lauri"></div>
    </modalWindow>
  </div>
</template>



<script>
import modalWindow from "./modalWindow"
import hamMenu from "./hamMenu"
import Vue from 'vue'

export default {
  name: "indexMain",
  components: {
    modalWindow,
    hamMenu
  },
  data() {
    return {
      gustav_modal_shown: false,
      lauri_modal_shown: false,
      header_logo: true
    }
  },
  methods: {
    showModal() {
      this.$store.commit("setShowModal")
    },
    toggleMobileMenu() {
      if (this.mobileMenuState == false) {
        this.$store.commit("setShowMobileMenu")
        if (window.matchMedia("(orientation: landscape)").matches) {
          this.header_logo = false
        }
      } else {
        this.$store.commit("setHideMobileMenu")
        this.header_logo = true
      }
    },
  },
  computed: {
    modalState() {
      return this.$store.state.showModal
    },
    mobileMenuState() {
      return this.$store.state.showMobileMenu
    }
  },
  watch: {
    mobileMenuState() {
      document.body.style.overflow = this.mobileMenuState ? 'hidden' : ''
    },
    modalState() {
      document.body.style.overflow = this.modalState ? 'hidden' : ''
    },
    header_logo() {
      if (this.mobileMenuState == true && (window.matchMedia("(orientation: landscape)").matches)) {
        this.header_logo = false
      } else {
        this.header_logo = true
      }
    }
  },
}
</script>
