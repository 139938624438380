//Global
import Vue from 'vue'
import App from './App.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'

//Tools
import { store } from './store';

//Styles
import './assets/sass/_app.scss'


//Main
Vue.config.productionTip = false;


new Vue({
  store,
  render(createElement) {
    return createElement(App)
  },
  created(){
    AOS.init()
  }
}).$mount('#app')
