import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        showModal: false,
        showMobileMenu:false

    },
    mutations: {
        setShowModal(state) {
            state.showModal = true
        },
        setHideModal(state) {
            state.showModal = false
        },
        setShowMobileMenu(state){
            state.showMobileMenu = true
        },
        setHideMobileMenu(state){
            state.showMobileMenu = false
        }
    },
    actions: {},
    getters: {},
});