<template>
  <div class="modal-wrapper">
    <div class="modal-container">
      <div class="modal-body">
        <slot name="modal-body-gustav">
          <div class="modal-clearfix">
            <div class="modal-title">

              <div class="modal-title-description">
                <h3>Gustav Korobov</h3>
                <p>Founder/CEO</p>
              </div>
                                      <slot name="header">
            <span class="close" @click="hideModal">
              <img src="../assets/img/close.svg" alt="close" />
            </span>
          </slot>
            </div>
            <div class="description">
              <img src="../assets/img/gustavbig.png" alt="gustav" />
              <p class="bio">
                Gustav is seasoned fintech professional with solid track-record on
                various
                successful
                financial
                technology project implementations to full scale business set ups.
              </p>
            </div>
          </div>
        </slot>

        <slot name="modal-body-lauri">
          <div class="modal-clearfix">
            <div class="modal-title">
              <div class="modal-title-description">
                <h3>Lauri Kärner</h3>
                <p>Principal/Business development</p>
              </div>
              <slot name="header">
                <span class="close" @click="hideModal">
                  <img src="../assets/img/close.svg" alt="close" />
                </span>
              </slot>
            </div>
            <div class="description">
              <img src="../assets/img/lauribig.png" alt="lauri" />
              <p class="bio">
                Lauri has been holding several trading, technology and management
                positions
                in financial
                community
                over the 2 decades.
              </p>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modalWindow",
  methods: {
    hideModal() {
      this.$store.commit("setHideModal");
    }
  }
};
</script>